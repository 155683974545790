import { graphql, PageProps } from 'gatsby'
import React from 'react'

import ArticlePreview from '@/components/article-preview'
import Layout from '@/components/layout'
import Seo from "@/components/seo";

const BlogIndex: React.FC<PageProps<GatsbyTypes.BlogQuery>> = ({ data }) => {
    if (data.allContentfulBlog.nodes.length === 0) {
        return <></>
    }

    const latestPost = data.allContentfulBlog.nodes[0]
    const posts = data.allContentfulBlog.nodes

    const postTitle: string = latestPost.title ?? ''
    const postImageUrl: string = latestPost?.image?.resize?.src ?? ''

    return (
      <Layout title={postTitle}>
          <Seo
            title={postTitle}
            description={latestPost?.shortDescription ?? ''}
            image={postImageUrl}
            meta={[]}
          />
          <ArticlePreview posts={posts} />
      </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
    query Blog{
        allContentfulBlog(sort: {fields: createdAt}) {
            nodes {
                id
                title
                shortDescription
                body {
                    raw
                }
                image {
                    gatsbyImageData
                    resize {
                        src
                    }
                }
                tags
                publishedAt
            }
        }
    }
`
